import { MailLockOutlined, ScoreboardOutlined } from '@mui/icons-material';
import MAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';

export default function AppBar(props: any) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <MAppBar position="static" elevation={0} sx={{ background: 'transparent' }}>
        <Toolbar>
          <Button target="_blank" href='https://scorerere.com' variant="text" size='large' startIcon={<ScoreboardOutlined />} sx={{textTransform:'none',color:'black',fontWeight:'bold'}}>
            scorerere.com
          </Button>
          <Box sx={{ flexGrow: 1 }}>

          </Box>

          <Button href="mailto: duolingquan@gmail.com" variant="text" sx={{textTransform:'none',color:'black'}} endIcon={<MailLockOutlined />} >
            scoreboard record score
          </Button>
        </Toolbar>
      </MAppBar>
    </Box>
  );
}
