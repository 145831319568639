import { useState } from "react";
import { InitArr } from "../utils";

type ScoreReturnType = (arr?: {
    id: number;
    color: string;
    player: string;
    Score: number;
    RScore: number;
},onChange?: (v: any) => void) => [
        {
            id: number;
            color: string;
            player: string;
            Score: number;
            RScore: number;
        }, boolean, () => void, () => void, () => void,(a: any) => void
    ]


const useScore: ScoreReturnType = (arr = InitArr[0],onChange=(v:any)=>{}) => {
    const [RMode, setRMode] = useState(false)
    const [data, setData] = useState(arr)
    const addRScore = () => {
        if (!RMode) {
            setRMode(true)
        } else {
            changeData({ ...data, RScore: data['RScore'] + 1 })
        }
    }

    const addScore = () => {
        if (RMode) {
            setRMode(false)
        } else {
            changeData({ ...data, Score: data['Score'] + 1 })
        }
    }

    const decreScore=()=>{
        if (RMode) {
            changeData({ ...data, RScore: data['RScore'] - 1 })
        } else {
            changeData({ ...data, Score: data['Score'] - 1 })
        }
    }

    const changeData=(a:any)=>{
        setData(a)
        onChange(a)
    }

    return [data, RMode, addScore, addRScore, decreScore,changeData]

}

export default useScore