import { CSSProperties } from 'react';

import DecreButton from '../../components/DecreButton';
import Dialog from '../../components/Dialog';
import useScore from '../../hooks/useScore';

const RScoreStyle: CSSProperties = { textAlign: 'center', flex: 1, color: 'white',display: 'flex', alignItems: 'center',justifyContent:'center' ,  }


export default function HCard(props: any) {
    const left = props.side === 'left'

    const [data, RMode, addScore, addRScore,decreScore, changeData] = useScore(props.data,props.onChange)

    return <div style={{ height: '100vh', width: '50vw', backgroundColor: data.color, color: 'white', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
        <Dialog
            data={data}
            dataChange={changeData}
            handleClose={(v: any) => {
                if (v === 'reset') {
                    changeData({ ...data, RScore: 0, Score: 0 })
                }
            }} >
            <Title player={data.player} left={left} />
        </Dialog>

        <div style={{ height: "70vh", width: '50vw', display: 'flex', }}>
            {!left &&
                <div style={{ ...RScoreStyle, fontSize: RMode ? '88px' : '33px',height:'70vh' }} onClick={addRScore}>{data.RScore}</div>
            }
            <div style={{ fontSize: RMode ? '33px' : '88px',display: 'flex', alignItems: 'center',justifyContent:'center' , flex: 4,height:'70vh' }}
                onClick={addScore}
            >{data.Score}</div>

            {left &&
                <div style={{ ...RScoreStyle, fontSize: RMode ? '88px' : '33px', }} onClick={addRScore}>{data.RScore}</div>
            }
        </div>
        <div style={{ display: 'flex', justifyContent: left ? 'start' : 'end', flexDirection: 'row', paddingBottom: '22px' }}>
            <DecreButton onClick={decreScore} />
        </div>
    </div>
}

const Title = (props: any) => {
    return <div style={{ fontSize: '22px', height: '10vh', width: '50vw', display: 'flex', flexDirection: 'row' }}>
        {!props.left && <div style={{ flex: 1 }}></div>}
        <div style={{ flex: 4, textAlign: 'center', }}>{props.player}</div>
        {props.left && <div style={{ flex: 1 }}></div>}
    </div>
}
