import {  useState } from 'react';

import { TitleDialog } from '../../components/Dialog';
import { AppContext, InitArr } from '../../utils';
import HCard from './HCard';


export default function HorizonView(props: any) {
  return <>
    <Title />
    <PlayList />
  </>

}

const PlayList = (props:any) => {
  const [VList, setVList] = useState(InitArr)
  
  return <div style={{ display: 'flex', zIndex: 10 }}>
    <HCard data={VList[0]} side='left' onChange={(a:any)=>{
        AppContext.arr[0]=a
    }} />
    <HCard data={VList[1]} side='right' onChange={(a:any)=>{
        AppContext.arr[1]=a
    }}/>
  </div>
}

const Title = (props: any) => {

  const [data, setData] = useState({ title:  AppContext.title })


  return <TitleDialog data={data} dataChange={(n: any) => {
    setData(n)
  }}>
    <div style={{ cursor:'pointer',marginLeft: '17vw', position: 'fixed', zIndex: 1, color: 'white', width: '66vw', bottom: '0px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <h5>{data.title}</h5>
    </div>
  </TitleDialog>
}