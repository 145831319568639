import React from 'react';
import { useState } from 'react';

import AddButton from '../../components/AddButton';
import AppBar from '../../components/AppBar';
import { TitleDialog } from '../../components/Dialog';
import { AppContext, ColorArr, InitArr } from '../../utils';
import VCard from './VCard';

export default function VerticleView(props: any) {
  return <>
    <AppBar handleHoriz={props.handleHoriz} />

    <div style={{ width: '100vw', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Title />
      <PlayList />
    </div>
  </>
}

const Title = (props: any) => {
  const [data, setData] = useState({ title:  AppContext.title })


  return <TitleDialog data={data} dataChange={(n: any) => {
    setData(n)
  }}>
    <div style={{cursor:'pointer', textAlign: 'center', width: '90vw', fontSize: '18px', marginBottom: '28px', marginTop: '22px', fontWeight: 'bold' }}>{data.title}</div>
  </TitleDialog>
}

const PlayList = () => {
  const [VList, setVList] = useState(InitArr)
  const [idp, setIdp] = useState(2)

  return <>
    {
      VList.map((e, idx) => <VCard key={e['id']} data={e}
        onDelete={() => {
          VList.splice(idx, 1)
          setVList([...VList])
        }}
        onChange={(n: any) => {
          let newArr = [...VList]
          newArr[idx] = n
          if(idx<2){
            AppContext.arr[idx]=n
          }
          setVList(newArr)
        }}
      />)
    }

    <AddButton onClick={() => {
      const CIdx = idp % ColorArr.length
      const NV = {
        id: idp,
        color: ColorArr[CIdx],
        player: `player ${idp + 1}`,
        Score: 0,
        RScore: 0
      }
      setIdp(idp + 1)
      setVList([...VList, NV])
    }} />
  </>

}