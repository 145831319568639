import { DeleteOutline, Redo } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import MDialog from '@mui/material/Dialog';
import Input from '@mui/material/Input';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useContext } from 'react';

import { C } from '../App';
import { AppContext } from '../utils';
import ColorSelect from './ColorSelect';


const DialogListItem = (props: any) => <ListItem button onClick={props.onClick} key={'reset'}>
    <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'white', color: props.color }}>
            {props.children}
        </Avatar>
    </ListItemAvatar>
    <ListItemText primary={props.title} />
</ListItem>

export default function Dialog(props: any) {
    const [open, setOpen] = React.useState(false);
    console.log('propsprops', props.data)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value: string) => {
        setOpen(false);
        if (props.handleClose)
            props.handleClose(value)
    };
    const handleDataChange = (nd: any) => {
        setOpen(false);
        if (props.dataChange)
            props.dataChange(nd)
    }

    return (
        <div>
            <div onClick={handleClickOpen}>
                {props.children}
            </div>

            <MDialog onClose={handleClose} open={open}>
                <Input sx={{ margin: '6px', width: '80vw' }} multiline defaultValue={props.data ? props.data.player : 'player'} onChange={(o) => {
                    if (props.dataChange)
                        props.dataChange({
                            ...props.data, player: o.target.value
                        })
                }} />

                <ColorSelect color={props.data ? props.data['color'] : undefined} colorChange={(c: any) => {
                    handleDataChange({
                        ...props.data, color: c
                    })
                }} />
                <List sx={{ pt: 0 }}>
                    <DialogListItem title='reset' color='black' onClick={() => {
                        handleClose('reset')
                    }}>
                        <Redo />
                    </DialogListItem>
                    <DialogListItem title='delete' color='red' onClick={() => {
                        handleClose('delete')
                    }}>
                        <DeleteOutline />
                    </DialogListItem>
                </List>
            </MDialog>
        </div>
    );
}

export function TitleDialog(props: any) {
    const [open, setOpen] = React.useState(false);
    const {horizon, setHorizon}=useContext(C)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value: string) => {
        setOpen(false);
        if (props.handleClose)
            props.handleClose(value)
    };


    return <div>
        <div onClick={handleClickOpen}>
            {props.children}
        </div>
        <MDialog onClose={handleClose} open={open}>
            <Input autoFocus sx={{ margin: '6px', width: '80vw' }} multiline defaultValue={props.data ? props.data.title : 'title'} onChange={(o) => {
                const t=o.target.value
                AppContext.title=o.target.value
                if (props.dataChange)
                    props.dataChange({
                        ...props.data, title: t
                    })
            }} />
            <List sx={{ pt: 0 }}>
                    <DialogListItem title={horizon?'to NvN':'to 1v1 (rotate screen)'} color='red' onClick={() => {
                        setHorizon(!horizon)
                        setOpen(false);
                        }}>
                        <DeleteOutline />
                    </DialogListItem>
                </List>
        </MDialog>
    </div>
}