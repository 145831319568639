import { Button } from '@mui/material';
import DecreButton from '../../components/DecreButton';
import Dialog from '../../components/Dialog';
import useScore from '../../hooks/useScore';

export default function VCard(props: any) {
    const [data, RMode, addScore, addRScore, decreScore, changeData] = useScore(props.data, props.onChange)

    return <div style={{ marginBottom: '33px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', backgroundColor: data.color, width: '90vw', color: 'white', paddingRight: '0px', paddingLeft: '0px', borderRadius: '16px' }}>
        <Dialog
            data={data}
            dataChange={changeData}
            handleClose={(v: any) => {
                console.log('vvvv', v)
                if (v === 'delete') {
                    props.onDelete()
                } else if (v === 'reset') {
                    changeData({ ...data, RScore: 0, Score: 0 })
                }
            }} >
            <div style={{ cursor:'pointer',paddingTop: '11px', paddingBottom: '11px', paddingRight: '16px', paddingLeft: '16px', fontSize: '18px', fontWeight: 'bold' }}>{data.player}</div>
        </Dialog>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', paddingRight: '16px', paddingLeft: '16px', alignItems: 'center', paddingTop: '13px', paddingBottom: '18px' }}>
            <div style={{cursor:'pointer', fontSize: RMode ? '55px' : '22px' ,color:'white'}} onClick={addRScore}>{data['RScore']}</div>

            <div style={{ cursor:'pointer',fontSize: RMode ? '22px' : '55px', width: '66vw', textAlign: 'center' }} onClick={addScore}>{data['Score']}</div>
            <DecreButton onClick={decreScore} />

        </div>

    </div>
}

