export const ColorArr = ['#1199EE', '#22AA99', '#EE4444', '#4455BB', '#000000', '#66BB66',
'#FF7744', '#555555', '#1F2961', '#C1AE1F', '#6B750F']

export const DefaultTitle='Happy club Champions League'

export const InitArr=[
    {
        id:0,
      color:ColorArr[0],
      player:`player 1`,
      Score:0,
      RScore:0
    },
    {
        id:1,
      color:ColorArr[1],
      player:`player 2`,
      Score:0,
      RScore:0
    }
  ]

  export const AppContext = {
  title:DefaultTitle,
  arr:InitArr
};
