import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';

import { ColorArr } from '../utils';

const ColorItem = (props: any) => {
    return <div style={{ backgroundColor: props.color, height: '5vh', borderRadius: '16' }} />
}

export default function ColorSelect(props: any) {
    const [age, setAge] = React.useState(props.color);

    const handleChange = (event: SelectChangeEvent) => {
        const nC=event.target.value as string
        setAge(nC);
        props.colorChange(nC)
    };

    return (
        <Box sx={{ minWidth: 120, margin: '6px' }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">color</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                >
                    {ColorArr.map((c) =>
                        <MenuItem value={c} key={c} style={{ margin: '5px', backgroundColor: c }} >
                            <ColorItem color={c} />
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </Box>
    );
}
