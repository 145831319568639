import './App.css';

import React, { createContext } from 'react';
import { useState } from 'react';

import HorizonView from './pages/HorizonPage/HorizonView';
import VerticleView from './pages/VerticlePage/VerticleView';

export const C = createContext<any>(null);

function App() {
  const [horizon, setHorizon] = useState(false)

  return (
    <C.Provider value={{ horizon, setHorizon }}>

    <div>
      {horizon ? <HorizonView  /> : 
      <VerticleView />}
    </div>
    </C.Provider>

  );
}


export default App;
